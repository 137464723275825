import useAboutYourCarQuestions from 'forms/AboutYourCarForm/questions';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';

export type EditAddressMtaQuestions = {
  overnightParkingLocation: QuestionWithPlaceholder;
  isParkedAtHomeAddressOvernight: Question;
  overnightParkingPostcode: QuestionWithOptionalPlaceholder;
  daytimeParkingLocation: QuestionWithPlaceholder;
  carUse: QuestionWithPlaceholder;
  personalMileage: QuestionWithPlaceholder;
  businessMileage: QuestionWithPlaceholder;
};

const useEditAddressMtaQuestions = (): EditAddressMtaQuestions => {
  const questions = useAboutYourCarQuestions();
  return questions;
};

export default useEditAddressMtaQuestions;

import {
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { isBusinessUse } from 'api/businessLogic/carUse';
import { graphql, useStaticQuery } from 'gatsby';
import useAddressRules from 'forms/AboutYouForm/AddressForm/validation';
import { POSTCODE_REGEX } from 'helpers/regexes';
import { EditAddressMtaDetails } from 'state/formData/mta/editAddress';
import { MigratedVehicleDetailsFlags } from 'state/policy/policy';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsEditAddressMtaErrorMessages = {
  csAboutYourCarPartOne: {
    car_use: CsErrorsMissingOnly;
    personal_mileage: CsErrorsMissingOnly;
    business_mileage: CsErrorsMissingOnly;
  };
  csAboutYourCarPartTwo: {
    overnight_parking_location: CsErrorsMissingOnly;
    parked_at_home_address_overnight: CsErrorsMissingOnly;
    overnight_parking_postcode: {
      error_messages: {
        missing: string;
        invalid_postcode: string;
        too_short: string;
      };
    };
    daytime_parking_location: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csAboutYourCarPartOne {
      car_use {
        error_messages {
          missing
        }
      }
      personal_mileage {
        error_messages {
          missing
        }
      }
      business_mileage {
        error_messages {
          missing
        }
      }
    }
    csAboutYourCarPartTwo {
      overnight_parking_location {
        error_messages {
          missing
        }
      }
      parked_at_home_address_overnight {
        error_messages {
          missing
        }
      }
      overnight_parking_postcode {
        error_messages {
          missing
          invalid_postcode
          too_short
        }
      }
      daytime_parking_location {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useEditAddressMtaRules = (
  migratedFields?: MigratedVehicleDetailsFlags
): ValidationRules<EditAddressMtaDetails> => {
  const errorMessages = useStaticQuery<CsEditAddressMtaErrorMessages>(query);

  const addressRules = useAddressRules();

  return {
    ...addressRules,
    overnightParkingLocation: validateIf(
      () => !migratedFields?.vehicleKeptOvernightIsMigrated,
      [
        required(
          errorMessages.csAboutYourCarPartTwo.overnight_parking_location.error_messages
            .missing
        ),
      ]
    ),
    isParkedAtHomeAddressOvernight: [
      required(
        errorMessages.csAboutYourCarPartTwo.parked_at_home_address_overnight
          .error_messages.missing
      ),
    ],
    overnightParkingPostcode: validateIf(
      (data) =>
        data.isParkedAtHomeAddressOvernight === false &&
        !migratedFields?.postcodeOfVehicleOvernightIsMigrated,
      [
        required(
          errorMessages.csAboutYourCarPartTwo.overnight_parking_postcode.error_messages
            .missing
        ),
        lengthGreaterOrEqualTo(
          5,
          errorMessages.csAboutYourCarPartTwo.overnight_parking_postcode.error_messages
            .too_short
        ),
        matchesRegex(
          POSTCODE_REGEX,
          errorMessages.csAboutYourCarPartTwo.overnight_parking_postcode.error_messages
            .invalid_postcode
        ),
      ]
    ),
    daytimeParkingLocation: validateIf(
      () => !migratedFields?.carKeptDuringDayIsMigrated,
      [
        required(
          errorMessages.csAboutYourCarPartTwo.daytime_parking_location.error_messages
            .missing
        ),
      ]
    ),
    carUse: [
      required(errorMessages.csAboutYourCarPartOne.car_use.error_messages.missing),
    ],
    personalMileage: [
      required(
        errorMessages.csAboutYourCarPartOne.personal_mileage.error_messages.missing
      ),
    ],
    businessMileage: validateIf(isBusinessUse, [
      required(
        errorMessages.csAboutYourCarPartOne.business_mileage.error_messages.missing
      ),
    ]),
  };
};

export default useEditAddressMtaRules;

import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  CsQuestion,
  CsQuestionWithPlaceholder,
  CsRepeatableSection,
} from 'types/contentStack';
import {
  NestedQuestions,
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';

export type AboutYourCarQuestions = {
  carValue: QuestionWithOptionalPlaceholder;
  carUse: QuestionWithPlaceholder;
  personalMileage: QuestionWithPlaceholder;
  businessMileage: QuestionWithPlaceholder;
  hasModifications: Question;
  modifications: NestedQuestions<{
    modificationType: QuestionWithPlaceholder;
    modificationDetail: QuestionWithPlaceholder;
  }>;
  hasSecurityOrTrackingDevice: Question;
  alarmOrImmobiliser: QuestionWithPlaceholder;
  tracker: QuestionWithPlaceholder;
  isLegalOwner: Question;
  legalOwner: QuestionWithPlaceholder;
  isRegisteredKeeper: Question;
  registeredKeeper: QuestionWithPlaceholder;
  legalOwnerDuration: QuestionWithPlaceholder;
  overnightParkingLocation: QuestionWithPlaceholder;
  isParkedAtHomeAddressOvernight: Question;
  overnightParkingPostcode: QuestionWithOptionalPlaceholder;
  daytimeParkingLocation: QuestionWithPlaceholder;
};

type CsAboutYourCarQuestions = {
  csAboutYourCarPartOne: {
    car_value: CsQuestionWithPlaceholder;
    car_use: CsQuestionWithPlaceholder;
    personal_mileage: CsQuestionWithPlaceholder;
    business_mileage: CsQuestionWithPlaceholder;
    modifications: CsRepeatableSection;
    car_has_modifications: CsQuestion;
    modification_type: CsQuestionWithPlaceholder;
    modification_detail: CsQuestionWithPlaceholder;
    security_tracking_devices: CsQuestion;
    alarm_or_immobiliser: CsQuestionWithPlaceholder;
    tracker: CsQuestionWithPlaceholder;
  };
  csAboutYourCarPartTwo: {
    is_legal_owner: CsQuestion;
    legal_owner: CsQuestionWithPlaceholder;
    is_registered_keeper: CsQuestion;
    registered_keeper: CsQuestionWithPlaceholder;
    legal_owner_duration: CsQuestionWithPlaceholder;
    overnight_parking_location: CsQuestionWithPlaceholder;
    parked_at_home_address_overnight: CsQuestion;
    overnight_parking_postcode: CsQuestionWithPlaceholder;
    daytime_parking_location: CsQuestionWithPlaceholder;
  };
};

const query = graphql`
  query {
    csAboutYourCarPartOne {
      car_value {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      car_use {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      personal_mileage {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      business_mileage {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      modifications {
        label
        add_text
        remove_text
      }
      car_has_modifications {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      modification_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      modification_detail {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      security_tracking_devices {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      alarm_or_immobiliser {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      tracker {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
    csAboutYourCarPartTwo {
      is_legal_owner {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      legal_owner {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      is_registered_keeper {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      registered_keeper {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      legal_owner_duration {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      overnight_parking_location {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      parked_at_home_address_overnight {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      overnight_parking_postcode {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      daytime_parking_location {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
  }
`;

const useAboutYourCarQuestions = (): AboutYourCarQuestions => {
  const csQuestions = useStaticQuery<CsAboutYourCarQuestions>(query);
  const {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
    processQuestionWithPlaceholder,
  } = useQuestionProcessor();

  return {
    carValue: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYourCarPartOne.car_value
    ),
    carUse: processQuestionWithPlaceholder(csQuestions.csAboutYourCarPartOne.car_use),
    personalMileage: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartOne.personal_mileage
    ),
    businessMileage: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartOne.business_mileage
    ),
    hasModifications: processQuestion(
      csQuestions.csAboutYourCarPartOne.car_has_modifications
    ),
    modifications: {
      label: csQuestions.csAboutYourCarPartOne.modifications.label,
      addText: csQuestions.csAboutYourCarPartOne.modifications.add_text,
      removeText: csQuestions.csAboutYourCarPartOne.modifications.remove_text,
      modificationType: processQuestionWithPlaceholder(
        csQuestions.csAboutYourCarPartOne.modification_type
      ),
      modificationDetail: processQuestionWithPlaceholder(
        csQuestions.csAboutYourCarPartOne.modification_detail
      ),
    },
    hasSecurityOrTrackingDevice: processQuestion(
      csQuestions.csAboutYourCarPartOne.security_tracking_devices
    ),
    alarmOrImmobiliser: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartOne.alarm_or_immobiliser
    ),
    tracker: processQuestionWithPlaceholder(csQuestions.csAboutYourCarPartOne.tracker),
    isLegalOwner: processQuestion(csQuestions.csAboutYourCarPartTwo.is_legal_owner),
    legalOwner: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartTwo.legal_owner
    ),
    isRegisteredKeeper: processQuestion(
      csQuestions.csAboutYourCarPartTwo.is_registered_keeper
    ),
    registeredKeeper: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartTwo.registered_keeper
    ),
    legalOwnerDuration: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartTwo.legal_owner_duration
    ),
    overnightParkingLocation: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartTwo.overnight_parking_location
    ),
    isParkedAtHomeAddressOvernight: processQuestion(
      csQuestions.csAboutYourCarPartTwo.parked_at_home_address_overnight
    ),
    overnightParkingPostcode: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYourCarPartTwo.overnight_parking_postcode
    ),
    daytimeParkingLocation: processQuestionWithPlaceholder(
      csQuestions.csAboutYourCarPartTwo.daytime_parking_location
    ),
  };
};

export default useAboutYourCarQuestions;

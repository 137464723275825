import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import LoadPolicyWrapper from 'components/ApiRequestWrapper/LoadPolicyWrapper';
import Layout from 'components/Layout';
import MtaEditAddressPage from 'components/MtaEditAddress/MtaEditAddressPage';
import MtaStartDatePage from 'components/MtaStartDate/MtaStartDatePage';
import useMtaStartDatePage from 'components/MtaStartDate/useMtaStartDatePage';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type ChangeAddressProps = {
  data: {
    csMtaEditAddress: {
      meta: CsMeta;
    };
  };
};

export const query = graphql`
  query {
    csMtaEditAddress {
      meta {
        meta_title
      }
    }
  }
`;

const ChangeAddress: React.FC<ChangeAddressProps & PolicyNumberProps> = ({
  data: {
    csMtaEditAddress: { meta },
  },
  policyNumber,
}) => {
  const { isLoading, loadingMessage, requestHandler } = useApiRequestHandler();
  const {
    startDateFormProps,
    hasExistingMtaRequest,
    showStartDatePage,
    toggleStartDatePage,
    moveNextOrGenerateMta,
  } = useMtaStartDatePage(policyNumber, 'Edit address', requestHandler);

  useEffect(() => trackCheckoutEvent(1, null, 'MTA'), []);

  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn);

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadPolicyWrapper policyNumber={policyNumber}>
        <LoadMtaQuoteWrapper policyNumber={policyNumber}>
          {isLoading && <LoadingOverlay loadingMessage={loadingMessage || ''} />}
          {!showStartDatePage ? (
            <MtaEditAddressPage
              hasExistingMtaRequest={hasExistingMtaRequest}
              moveNext={moveNextOrGenerateMta}
              policyNumber={policyNumber}
            />
          ) : (
            <MtaStartDatePage
              formState={startDateFormProps.formState}
              updateFormState={startDateFormProps.updateFormState}
              moveNext={moveNextOrGenerateMta}
              moveBack={toggleStartDatePage}
            />
          )}
        </LoadMtaQuoteWrapper>
      </LoadPolicyWrapper>
    </Layout>
  );
};

export default withPolicyNumber(ChangeAddress);

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { REFERENCE_DATA_REQUESTED } from './actions';
import { ReferenceData, ReferenceDataKey } from './state';

const useReferenceData = <TKey extends ReferenceDataKey>(
  dataType: TKey
): ReferenceData[TKey] | undefined => {
  const referenceData = useSelector(
    (state: RootState) => state.referenceData[dataType]?.data
  ) as ReferenceData[TKey] | undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: REFERENCE_DATA_REQUESTED, dataType });
  }, [dispatch, dataType]);

  return referenceData;
};

export default useReferenceData;

import { isValueBusinessUse } from 'api/businessLogic/carUse';
import mapAddress from 'api/quote/quoteToFormMappings/mapAddress';
import { conditionalValue } from 'helpers/mappingHelpers';
import { EditAddressMtaDetails } from 'state/formData/mta/editAddress';
import { MtaQuote } from '../../../state/mta/mtaQuote';

const mapEditAddressDetails = (mtaQuote: MtaQuote): EditAddressMtaDetails => ({
  ...mapAddress(mtaQuote.account.address),
  overnightParkingLocation: mtaQuote.vehicle.vehicleKeptOvernight,
  isParkedAtHomeAddressOvernight: mtaQuote.vehicle.atHomeAddress,
  overnightParkingPostcode:
    conditionalValue(
      !mtaQuote.vehicle.atHomeAddress,
      mtaQuote.vehicle.postcodeOfVehicleOvernight
    ) || '',
  daytimeParkingLocation: mtaQuote.vehicle.carKeptDuringDay,
  carUse: mtaQuote.policyHolder.useOfVehicle,
  personalMileage: mtaQuote.policyHolder.annualPersonalMileage.toString(),
  businessMileage:
    conditionalValue(
      isValueBusinessUse(mtaQuote.policyHolder.useOfVehicle),
      mtaQuote.policyHolder.annualBusinessMileage?.toString()
    ) || '',
});

export default mapEditAddressDetails;

import SelectInput from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import {
  BooleanField,
  QuestionWithPlaceholderField,
  StringField,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isBusinessUse } from 'api/businessLogic/carUse';
import React, { ReactElement } from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/FormFooter';
import QuestionField from 'components/QuestionField';
import AddressForm from 'forms/AboutYouForm/AddressForm';
import { FormNavProps } from 'forms/formNavProps';
import {
  trackDropdownSelect,
  trackFieldError,
  trackSelectOrTypeaheadFocus,
  trackTextInputFocus,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_ALPHANUMERIC_WITH_SPACE } from 'helpers/inputRegexes';
import {
  EditAddressMtaDetails,
  useEditAddressMtaDetails,
} from 'state/formData/mta/editAddress';
import { usePolicyData } from 'state/policy/policy';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import useEditAddressMtaQuestions, { EditAddressMtaQuestions } from './questions';
import useEditAddressMtaRules from './validation';

const EditAddressForm: React.FC<FormNavProps> = ({ moveNextText, moveNext }) => {
  const [editAddressMtaDetails, updateEditAddressMtaDetails] = useEditAddressMtaDetails();

  const policy = usePolicyData();
  const migratedFields = policy?.migratedFields?.vehicleDetails;

  const questions = useEditAddressMtaQuestions();
  const rules = useEditAddressMtaRules(migratedFields);

  const { getError, validateOnSubmit, showValidation } = useValidation(
    editAddressMtaDetails,
    rules,
    trackFieldError
  );

  const vehicleDetailsRefData = useReferenceData('vehicleDetails');
  const driverDetailsRefData = useReferenceData('driverDetails');

  const renderRefDataQuestion = (
    id: QuestionWithPlaceholderField<EditAddressMtaQuestions> &
      StringField<EditAddressMtaDetails>,
    options: ReferenceDataOption[] | undefined
  ): ReactElement => (
    <QuestionField question={questions[id]} errorText={getError(id)}>
      <SelectInput
        id={id}
        value={editAddressMtaDetails[id]}
        options={options ?? []}
        placeholder={questions[id].placeholder}
        onChange={(e) => {
          trackDropdownSelect(id, e.target.value);
          updateEditAddressMtaDetails({
            [id]: e.target.value,
          });
        }}
        onBlur={() => showValidation(id)}
        onFocus={trackSelectOrTypeaheadFocus(id)}
        loading={!options}
      />
    </QuestionField>
  );

  const renderBooleanQuestion = (
    id: Exclude<
      Exclude<BooleanField<EditAddressMtaDetails>, 'isManualAddress'>,
      'usePreviousAddress'
    >
  ): ReactElement => (
    <QuestionField question={questions[id]} errorText={getError(id)}>
      <BooleanRadioInput
        id={id}
        value={editAddressMtaDetails[id]}
        onChange={(newValue) => updateEditAddressMtaDetails({ [id]: newValue })}
      />
    </QuestionField>
  );

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <AddressForm
        addressDetails={editAddressMtaDetails}
        updateAddressDetails={updateEditAddressMtaDetails}
        showValidation={showValidation}
        getError={getError}
      />
      {!migratedFields?.vehicleKeptOvernightIsMigrated &&
        renderRefDataQuestion(
          'overnightParkingLocation',
          vehicleDetailsRefData?.vehicleKeptOvernight
        )}
      {renderBooleanQuestion('isParkedAtHomeAddressOvernight')}
      {!migratedFields?.postcodeOfVehicleOvernightIsMigrated &&
        editAddressMtaDetails.isParkedAtHomeAddressOvernight === false && (
          <QuestionField
            question={questions.overnightParkingPostcode}
            errorText={getError('overnightParkingPostcode')}>
            <TextInput
              id="overnightParkingPostcode"
              maxLength={8}
              value={editAddressMtaDetails.overnightParkingPostcode}
              placeholder={questions.overnightParkingPostcode.placeholder}
              onChange={(e) => {
                if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC_WITH_SPACE)) {
                  updateEditAddressMtaDetails({
                    overnightParkingPostcode: e.target.value.toUpperCase(),
                  });
                }
              }}
              onBlur={() => showValidation('overnightParkingPostcode')}
              onFocus={trackTextInputFocus('overnightParkingPostcode')}
            />
          </QuestionField>
        )}
      {!migratedFields?.carKeptDuringDayIsMigrated &&
        renderRefDataQuestion(
          'daytimeParkingLocation',
          vehicleDetailsRefData?.carKeptDuringDay
        )}
      {renderRefDataQuestion('carUse', driverDetailsRefData?.useOfVehicle)}
      {renderRefDataQuestion(
        'personalMileage',
        vehicleDetailsRefData?.annualPersonalMileage
      )}
      {isBusinessUse(editAddressMtaDetails) &&
        renderRefDataQuestion(
          'businessMileage',
          vehicleDetailsRefData?.annualBusinessMileage
        )}
      <FormFooter
        contentColumns={{ desktop: 10 }}
        moveNextButton={{
          text: moveNextText,
        }}
      />
    </form>
  );
};

export default EditAddressForm;

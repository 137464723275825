import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import styled, { css } from 'styled-components';

export const StyledRadioInput = styled(RadioInput)<{
  hasWhiteButtons: boolean;
}>`
  ${(props) =>
    props.hasWhiteButtons &&
    css`
      ${RadioItem} {
        background: ${colors.neutral08};
      }
      &&:hover ${RadioItem} {
        background: ${colors.neutral08};
      }
    `};
`;

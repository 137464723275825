export const POSTCODE_REGEX = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
export const LICENCE_REGEX = /^([A-Z0-9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z0-9]{3}[A-Z]{2})$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
export const PHONE_REGEX = /^0[0-9]{10}$/;

// This regex checks that the reference provided starts with 4 letters [a-zA-Z]{4}
// then has 9-11 digits (\d){9,11}
// followed by one final letter at the end [a-zA-Z]{1}
export const POLICY_OR_QUOTE_NUMBER_REGEX = /^[a-zA-Z]{4}(\d){9,11}[a-zA-Z]{1}$/;

// This regex checks that the string contains at least one lowercase letter, one uppercase
// letter, one digit, and is between 8 and 12 characters long.
//
// Explanation:
// The first three groups, of the form (?=.*[X]), check for the existence of any string
// (.*) followed by the thing we're checking for (X). Hence these three groups check for the
// lowercase  character, uppercase character and digit. (?= is a positive lookahead,
// meaning it matches in the string without 'moving' the point you're looking at.)
// The [a-zA-Z\d\w\W] matches any valid character (alphanumeric and symbols) and the
// {8,12} matches the length.
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,12}$/;

import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';
import { trackRadioButtonClick } from 'helpers/eventTracking';
import useBooleanAnswerText from 'helpers/useBooleanAnswerText';
import { StyledRadioInput } from './styles';

type BooleanButtonContent = {
  yes: string;
  no: string;
};

type BooleanRadioInputProps = {
  id: string;
  value: boolean | undefined;
  labelId?: string;
  onChange: (newValue: boolean) => void;
  'aria-invalid'?: boolean;
  'aria-describedby'?: string;
  customValues?: BooleanButtonContent | undefined;
  disabled?: boolean;
  whiteBackgroundButtons?: boolean;
  trackingYesLabel?: string;
  trackingNoLabel?: string;
};

const yesValue = 'yes';
const noValue = 'no';

const valueToString = (value: boolean | undefined): string => {
  if (value === undefined) {
    return '';
  }
  return value ? yesValue : noValue;
};

const getButtonContent = (
  defaultValues: BooleanButtonContent,
  customValues: BooleanButtonContent | undefined
): BooleanButtonContent => {
  if (!customValues) {
    return defaultValues;
  }
  return customValues;
};

const BooleanRadioInput: InputComponent<BooleanRadioInputProps> = ({
  id,
  value,
  onChange,
  labelId,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy,
  customValues,
  disabled,
  whiteBackgroundButtons = false,
  trackingNoLabel = noValue,
  trackingYesLabel = yesValue,
}) => {
  const defaultValues = useBooleanAnswerText();
  const { yes, no } = getButtonContent(defaultValues, customValues);

  return (
    <StyledRadioInput
      id={id}
      options={[
        { name: yes, value: yesValue },
        { name: no, value: noValue },
      ]}
      value={valueToString(value)}
      onChange={(e) => {
        onChange(e.target.value === yesValue);
        trackRadioButtonClick(
          id,
          e.target.value === yesValue ? trackingYesLabel : trackingNoLabel
        );
      }}
      labelId={labelId}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
      disabled={disabled}
      hasWhiteButtons={whiteBackgroundButtons}
    />
  );
};

BooleanRadioInput.isFieldSet = true;

export default BooleanRadioInput;

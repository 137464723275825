import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import mapEditAddressDetails from 'api/mta/quoteToFormMappings/mapEditAddressDetails';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import EditAddressForm from 'forms/MtaForms/EditAddressForm';
import { useEditAddressMtaDetails } from 'state/formData/mta/editAddress';
import { useMtaQuote } from 'state/mta/mtaQuote';
import { CsHero, CsMeta } from 'types/contentStack';

type MtaEditAddressPageContent = {
  csMtaEditAddress: {
    meta: CsMeta;
    hero: CsHero;
    next_button_text: string;
  };
  csMtaStartDate: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csMtaEditAddress {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      next_button_text
    }
    csMtaStartDate {
      next_button_text
    }
  }
`;

type MtaEditAddressPageProps = {
  hasExistingMtaRequest: boolean;
  moveNext: () => void;
  policyNumber: string;
};

const MtaEditAddressPage: React.FC<MtaEditAddressPageProps> = ({
  hasExistingMtaRequest,
  moveNext,
  policyNumber,
}) => {
  const {
    csMtaEditAddress: {
      hero: { heading, subheading },
      next_button_text,
    },
    csMtaStartDate: { next_button_text: startDateNextButtonText },
  } = useStaticQuery<MtaEditAddressPageContent>(query);

  const mtaQuote = useMtaQuote();
  const [, updateEditAddressMtaDetails] = useEditAddressMtaDetails();

  useEffect(() => {
    if (mtaQuote?.policyNumber === policyNumber && mtaQuote?.mtaType.isChangeOfAddress) {
      updateEditAddressMtaDetails(mapEditAddressDetails(mtaQuote));
    }
  }, [mtaQuote, policyNumber, updateEditAddressMtaDetails]);

  return (
    <>
      <HeroPlain heading={heading} subhead={subheading || undefined} />
      <EditAddressForm
        moveNextText={hasExistingMtaRequest ? startDateNextButtonText : next_button_text}
        moveNext={moveNext}
      />
    </>
  );
};

export default MtaEditAddressPage;

import {
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { POSTCODE_REGEX } from 'helpers/regexes';
import { AddressDetails } from 'state/formData/driverDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsAddressErrorMessages = {
  csAboutYouPostcodeLookup: {
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
        not_verified: string;
      };
    };
    address_select: CsErrorsMissingOnly;
  };
  csAboutYouManualAddress: {
    house: {
      error_messages: {
        missing_house_name_and_flat_name: string;
      };
    };
    flat: {
      error_messages: {
        missing_house_name_and_flat_name: string;
      };
    };
    street: CsErrorsMissingOnly;
    town: CsErrorsMissingOnly;
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
  };
};

const query = graphql`
  query {
    csAboutYouPostcodeLookup {
      postcode {
        error_messages {
          missing
          too_short
          invalid_format
          not_verified
          not_found
          api_unavailable
        }
      }
      address_select {
        error_messages {
          missing
        }
      }
    }
    csAboutYouManualAddress {
      house {
        error_messages {
          missing_house_name_and_flat_name
        }
      }
      flat {
        error_messages {
          missing_house_name_and_flat_name
        }
      }
      street {
        error_messages {
          missing
        }
      }
      town {
        error_messages {
          missing
        }
      }
      postcode {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
    }
  }
`;

const useAddressRules = (): ValidationRules<AddressDetails> => {
  const errorMessages = useStaticQuery<CsAddressErrorMessages>(query);

  return {
    postcodeLookup: validateIf((data) => !data.isManualAddress, [
      {
        validityCondition: (value) => !!value.lookupKey,
        errorMessage:
          errorMessages.csAboutYouPostcodeLookup.postcode.error_messages.missing,
      },
      {
        validityCondition: (value) =>
          value.lookupKey !== undefined && value.lookupKey.length >= 5,
        errorMessage:
          errorMessages.csAboutYouPostcodeLookup.postcode.error_messages.too_short,
      },
      {
        validityCondition: (value) => !!value.lookupKey.match(POSTCODE_REGEX),
        errorMessage:
          errorMessages.csAboutYouPostcodeLookup.postcode.error_messages.invalid_format,
      },
      {
        validityCondition: (_, data) =>
          data.usePreviousAddress || !!data.postcodeLookup.data,
        errorMessage:
          errorMessages.csAboutYouPostcodeLookup.postcode.error_messages.not_verified,
        onlyValidateAfterSubmission: true,
      },
    ]),
    address: validateIf(
      (data) =>
        data.usePreviousAddress || (!!data.postcodeLookup.data && !data.isManualAddress),
      [
        required(
          errorMessages.csAboutYouPostcodeLookup.address_select.error_messages.missing
        ),
      ]
    ),
    house: validateIf((data) => data.isManualAddress, [
      {
        validityCondition: (v, data) => !!(v || data.flat),
        errorMessage:
          errorMessages.csAboutYouManualAddress.house.error_messages
            .missing_house_name_and_flat_name,
      },
    ]),
    flat: validateIf((data) => data.isManualAddress, [
      {
        validityCondition: (v, data) => !!(v || data.house),
        errorMessage:
          errorMessages.csAboutYouManualAddress.flat.error_messages
            .missing_house_name_and_flat_name,
      },
    ]),
    street: validateIf((data) => data.isManualAddress, [
      required(errorMessages.csAboutYouManualAddress.street.error_messages.missing),
    ]),
    town: validateIf((data) => data.isManualAddress, [
      required(errorMessages.csAboutYouManualAddress.town.error_messages.missing),
    ]),
    postcode: validateIf((data) => data.isManualAddress, [
      required(errorMessages.csAboutYouManualAddress.postcode.error_messages.missing),
      lengthGreaterOrEqualTo(
        5,
        errorMessages.csAboutYouManualAddress.postcode.error_messages.too_short
      ),
      matchesRegex(
        POSTCODE_REGEX,
        errorMessages.csAboutYouManualAddress.postcode.error_messages.invalid_format
      ),
    ]),
  };
};

export const allowAddressLookup = (addressDetails: AddressDetails): boolean => {
  return !!addressDetails.postcodeLookup.lookupKey.match(POSTCODE_REGEX);
};

export default useAddressRules;
